(function($) {


  get_viewport_size = function() {
    var e = window,
    a = 'inner';
    if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
    }
    return { width: e[a + 'Width'], height: e[a + 'Height'] };
  };


  stickyNav = function()  {

    // if (get_viewport_size().width > 1200) {
    //   if ( $(window).scrollTop() > 150  ) {
    //     $('body').addClass('stickyNav');
    //   } else {
    //     $('body').removeClass('stickyNav');
    //   }
    // } else if (get_viewport_size().width < 1200) {
    //   // if ( $(window).scrollTop() > 150  ) {
    //   //   $('body').addClass('stickyNav');
    //   // } else {
    //   //   $('body').removeClass('stickyNav');
    //   // }
    // }
  };

  slickSlider = function() {
    $('.slick-slider--projects').slick({
      dots: true,
      infinite: true,
      autoplay: false,
      appendArrows: '.slick-arrows--projects',
      prevArrow: '<a class="slick-arrow prev">Vorige</a>',
      nextArrow: '<a class="slick-arrow next">Volgende</a>',
      arrows: true,
      speed: 300,
      slidesToShow: 2,
      fade: false,
      slidesToScroll: 1,
      centerMode: false,
      responsive: [
      {
        breakpoint: 768,
        settings: {
          autoplay: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          autoplay: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false
        }
      }
      ]
    });


    $('.slick-slider--news').slick({
      dots: true,
      infinite: true,
      autoplay: false,
      appendArrows: '.slick-arrows--news',
      prevArrow: '<a class="slick-arrow prev">Vorige</a>',
      nextArrow: '<a class="slick-arrow next">Volgende</a>',
      arrows: true,
      speed: 300,
      slidesToShow: 3,
      fade: false,
      slidesToScroll: 1,
      centerMode: false,
      responsive: [
      {
        breakpoint: 768,
        settings: {
          autoplay: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          centerMode: false,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          autoplay: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          centerMode: true,
        }
      }
      ]
    });
  };

  toggleNav = function() {
    // Togglefunction for the mobile menu. If toggled, a class is added to the body

    $("#nav-trigger").on('click', function(e){
      e.preventDefault();
      if($('body').hasClass('open-nav')) {
        $('body').removeClass('open-nav');
      }
      else {
        $('body').addClass('open-nav');
      }
    });

    $(".menu-item a").on('click', function(e) {
      if( $('body').hasClass('open-nav') ) {
        setTimeout(function(){
          $('body').removeClass('open-nav');
        }, 700);
      }
    });
  };

  magnificPopup = function() {
    $('.gallery').magnificPopup({
      delegate: 'a',
      type: 'image',
      tLoading: 'Loading image #%curr%...',
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0,1] // Will preload 0 - before current, and 1 after the current image
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
      }
    });
    $('.image-popup-link').magnificPopup({
      type: 'image'
    });
    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
      disableOn: 700,
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: true,
      iframe: {
        patterns: {
          youtube: {
            index: 'youtube.com',
            id: 'v=',
            src: 'https://www.youtube.com/embed/%id%?rel=0&autoplay=1'
          }
        }
      },
      callbacks: {
        beforeOpen: function() {
          startWindowScroll = $(window).scrollTop();
        },
        close: function() {
          $(window).scrollTop(startWindowScroll);
        }
      }
    });
    $('.popup-modal').magnificPopup({
      type: 'inline',
      preloader: false,
      focus: '#name',

    // When elemened is focused, some mobile browsers in some cases zoom in
    // It looks not nice, so we disable it:
    callbacks: {
      beforeOpen: function() {
        if($(window).width() < 700) {
          this.st.focus = false;
        } else {
          this.st.focus = '#name';
        }
      }
    }
  });
  };

  masonry = function() {
    $('.isotope').isotope({
      // options
      layoutMode: 'packery',
      itemSelector: '.infographic__block',
      percentPosition: true,
    });
  }

  animateAnchor = function() {
    $('a[href^="#"]:not(.popup-modal)').click(function(){

      var the_id = $(this).attr("href");
      $('html, body').animate({
        scrollTop:$(the_id).offset().top - 100
      }, 1000);

      $('body').removeClass('open-nav');
      return false;
    });
  };

  getUrlParameter = function(sParam) {
    var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
  };

  $.fn.accessibleDropDown = function () {
    var el = $(this);

    /* Make dropdown menus keyboard accessible */

    $("a", el).focus(function() {
      $(this).parents("li").addClass("hover");
    }).blur(function() {
      $(this).parents("li").removeClass("hover");
    });
    $("li", el).on('touchstart', function(e) {
      $(this).addClass("hover");
    });
  }
})(jQuery);
